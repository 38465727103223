import { AsyncPipe, NgIf, JsonPipe, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

interface ProfileSection {
  title: string;
  items: { label: string; value: any; icon?: string }[];
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  standalone: true,
  imports: [AsyncPipe, NgIf, JsonPipe, NgFor],
})
export class ProfileComponent implements OnInit {
  profileSections: ProfileSection[] = [];

  constructor(public auth: AuthService) {}

  // Helper method for type checking
  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  ngOnInit() {
    this.auth.user$.subscribe((profile) => {
      if (profile) {
        this.profileSections = [
          {
            title: 'Personal Information',
            items: [
              {
                label: 'Name',
                value: profile.name,
                icon: 'M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
              },
              {
                label: 'Email',
                value: profile.email,
                icon: 'M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
              }
            ]
          },
          {
            title: 'Account Details',
            items: [
              {
                label: 'User ID',
                value: profile.id,
                icon: 'M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z'
              }
            ]
          }
        ];
      }
    });
  }
}
