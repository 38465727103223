<div class="min-h-screen bg-black text-white flex flex-col items-center justify-center px-6 relative overflow-hidden">
  <!-- Animated Background Grid -->
  <div class="absolute inset-0 bg-grid opacity-10"></div>
  
  <!-- Moving Waves Background -->
  <div class="absolute inset-0">
    <svg class="absolute top-0 left-0 w-full h-auto opacity-20" id="waves"></svg>
  </div>

  <!-- Content -->
  <div class="relative z-10 max-w-4xl mx-auto text-center">
    <h1 class="text-5xl md:text-7xl font-extrabold neon-text cyberpunk-glitch mb-8">Who We Are</h1>
    
    <div class="space-y-8">
      <!-- Main Description -->
      <p class="text-xl md:text-2xl text-blue-300 max-w-3xl mx-auto leading-relaxed cyberpunk-font">
        ZeroSum Analytics is revolutionizing the crypto space with cutting-edge AI and blockchain-powered solutions.
      </p>

      <!-- Feature Grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-12">
        <!-- AI Trading -->
        <div class="bg-blue-500/5 p-6 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300">
          <h3 class="text-2xl font-bold text-blue-400 mb-3">AI-Powered Trading</h3>
          <p class="text-blue-300/80">Advanced machine learning algorithms analyzing market patterns in real-time.</p>
        </div>

        <!-- Blockchain Analytics -->
        <div class="bg-blue-500/5 p-6 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300">
          <h3 class="text-2xl font-bold text-blue-400 mb-3">Blockchain Analytics</h3>
          <p class="text-blue-300/80">Deep insights into on-chain data and market movements.</p>
        </div>

        <!-- Real-time Analysis -->
        <div class="bg-blue-500/5 p-6 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300">
          <h3 class="text-2xl font-bold text-blue-400 mb-3">Real-time Analysis</h3>
          <p class="text-blue-300/80">Instant market updates and predictive analytics at your fingertips.</p>
        </div>

        <!-- Secure Platform -->
        <div class="bg-blue-500/5 p-6 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300">
          <h3 class="text-2xl font-bold text-blue-400 mb-3">Secure Platform</h3>
          <p class="text-blue-300/80">Enterprise-grade security protecting your data and assets.</p>
        </div>
      </div>

      <!-- CTA Section -->
      <div class="mt-12">
        <button routerLink="/signup" 
                class="px-8 py-4 bg-blue-600 text-white font-bold text-lg rounded-lg overflow-hidden transform hover:scale-105 transition-all duration-300 shadow-neon-button">
          Join the Revolution
        </button>
      </div>
    </div>
  </div>
</div>
  