<div class="nav-container">
  <nav class="fixed top-0 left-0 w-full bg-black bg-opacity-80 backdrop-blur-md border-b border-blue-500/30 shadow-lg z-50">
    <div class="max-w-screen-xl mx-auto px-6 py-3 flex justify-between items-center">
      
      <!-- Navbar Toggle Button (Mobile) -->
      <button
        class="md:hidden text-blue-400 focus:outline-none neon-hover"
        (click)="isCollapsed = !isCollapsed"
        aria-label="Toggle navigation"
      >
        ☰
      </button>

      <!-- Navbar Links -->
      <div class="hidden md:flex items-center space-x-8">
        <a routerLink="/" class="text-blue-400 hover:text-blue-500 transition-all neon-hover">Home</a>
        <a routerLink="/terminal" class="text-blue-400 hover:text-blue-500 transition-all neon-hover">Terminal</a>
        <a routerLink="/about" class="text-blue-400 hover:text-blue-500 transition-all neon-hover">Who We Are</a>
        <a routerLink="/pricing" class="text-blue-400 hover:text-blue-500 transition-all neon-hover">Pricing</a>
        <a routerLink="/support" class="text-blue-400 hover:text-blue-500 transition-all neon-hover">Support</a>
      </div>

      <!-- Auth Buttons (Desktop) -->
      <div class="hidden md:flex items-center space-x-6">
        <ng-container *ngIf="(auth.isAuthenticated$ | async) === false">
          <button
            class="px-6 py-2 text-lg font-bold bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-700 transition-all neon-button mr-4"
            (click)="login()"
          >
            Log in
          </button>
          <button
            class="px-6 py-2 text-lg font-bold border border-blue-500 text-blue-400 rounded-lg shadow-md hover:bg-blue-500 hover:text-white transition-all"
            routerLink="/signup"
          >
            Sign up
          </button>
        </ng-container>

        <div *ngIf="auth.user$ | async as user" class="relative">
          <!-- Profile button -->
          <button class="focus:outline-none" (click)="toggleDropdown($event)">
            <img
              [src]="user.picture"
              alt="Profile picture"
              class="w-12 h-12 rounded-full border-2 border-blue-500 neon-border"
            />
          </button>

          <!-- Dropdown Menu (Holographic Style) -->
          <div 
            #dropdownMenu
            *ngIf="isDropdownOpen"
            class="absolute right-0 mt-2 bg-black bg-opacity-90 shadow-lg rounded-lg w-56 p-3 backdrop-blur-md neon-border"
          >
            <div class="flex items-center px-4 py-2 border-b border-blue-500/40">
              <p class="text-blue-300 font-semibold truncate w-full">
                {{ user.name }}
              </p>
            </div>
            <a routerLink="/profile" class="block px-4 py-2 text-blue-400 hover:text-blue-500 neon-hover">
              Profile
            </a>
            <button
              class="w-full text-left px-4 py-2 text-red-400 hover:text-red-500 neon-hover"
              (click)="logout()"
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Navbar Menu -->
    <div *ngIf="!isCollapsed" class="md:hidden bg-black bg-opacity-90 p-4">
      <a routerLink="/" class="block text-blue-400 py-2 hover:text-blue-500 neon-hover">Home</a>
      <a routerLink="/terminal" class="block text-blue-400 py-2 hover:text-blue-500 neon-hover">Terminal</a>
      <a routerLink="/about" class="block text-blue-400 py-2 hover:text-blue-500 neon-hover">Who We Are</a>
      <a routerLink="/pricing" class="block text-blue-400 py-2 hover:text-blue-500 neon-hover">Pricing</a>
      <a routerLink="/support" class="block text-blue-400 py-2 hover:text-blue-500 neon-hover">Support</a>

      <button
        *ngIf="(auth.isAuthenticated$ | async) === false"
        class="w-full bg-blue-500 text-white px-4 py-2 rounded-lg mt-2 hover:bg-blue-700 transition-all neon-button"
        (click)="login()"
      >
        Log in
      </button>

      <div *ngIf="auth.user$ | async as user" class="mt-4">
        <div class="flex items-center space-x-3">
          <img [src]="user.picture" alt="Profile" class="w-12 h-12 rounded-full border-2 border-blue-500 neon-border" />
          <p class="text-blue-300 font-semibold truncate">
            {{ user.name }}
          </p>
        </div>
        <a routerLink="/profile" class="block py-2 text-blue-400 hover:text-blue-500 neon-hover mt-2">Profile</a>
        <button
          class="w-full text-left py-2 text-red-400 hover:text-red-500 neon-hover mt-2"
          (click)="logout()"
        >
          Log out
        </button>
      </div>
    </div>
  </nav>
</div>
