import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth.service';
import { AsyncPipe, DOCUMENT, NgClass, NgIf } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    AsyncPipe,
    NgIf,
    NgClass,
    RouterLink,
    CommonModule
  ],
})
export class NavBarComponent {
  isCollapsed = true;
  isDropdownOpen = false;
  faUser = faUser;
  faPowerOff = faPowerOff;

  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router
  ) {}

  login() {
    this.router.navigate(['/login']);
  }

  logout() {
    this.auth.logout();
    this.isDropdownOpen = false;
    this.router.navigate(['/']);
  }

  toggleDropdown(event: Event) {
    event.stopPropagation(); // Prevents click from being immediately detected by `clickOutside`
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    // Close dropdown when clicking anywhere
    if (this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }
}
