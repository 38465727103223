<footer class="relative bg-black/90 backdrop-blur-xl border-t border-blue-500/30 text-blue-400 py-6 overflow-hidden">
  <!-- Animated grid background -->
  <div class="absolute inset-0 bg-grid opacity-10" id="footer-waves"></div>
  
  <!-- Main content -->
  <div class="max-w-7xl mx-auto px-4 relative z-10">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
      <!-- Left: Branding -->
      <div class="text-left">
        <h2 class="text-xl font-bold cyberpunk-glitch neon-text">ZeroSum Analytics</h2>
        <p class="text-sm text-blue-300 mt-2 cyberpunk-font">
          AI-Powered Crypto Trading Hub
        </p>
      </div>
      
      <!-- Center: Social Links -->
      <div class="flex justify-center space-x-6">
        <a href="#" class="neon-hover hover:text-blue-300 transition-colors duration-300">
          <fa-icon [icon]="faTwitter" class="text-xl"></fa-icon>
        </a>
        <a href="#" class="neon-hover hover:text-blue-300 transition-colors duration-300">
          <fa-icon [icon]="faGithub" class="text-xl"></fa-icon>
        </a>
        <a href="#" class="neon-hover hover:text-blue-300 transition-colors duration-300">
          <fa-icon [icon]="faDiscord" class="text-xl"></fa-icon>
        </a>
      </div>
      
      <!-- Right: Copyright -->
      <div class="text-right">
        <p class="text-sm text-blue-500/80 cyberpunk-font">
          © 2025 ZeroSum Analytics
        </p>
        <p class="text-xs text-blue-500/60 mt-1">
          All Rights Reserved
        </p>
      </div>
    </div>

    <!-- Bottom Links -->
    <div class="mt-6 pt-6 border-t border-blue-500/20">
      <div class="flex flex-wrap justify-center space-x-6 text-sm">
        <a routerLink="/about" class="neon-hover hover:text-blue-300 transition-colors duration-300">About</a>
        <a routerLink="/privacy" class="neon-hover hover:text-blue-300 transition-colors duration-300">Privacy</a>
        <a routerLink="/terms" class="neon-hover hover:text-blue-300 transition-colors duration-300">Terms</a>
        <a routerLink="/support" class="neon-hover hover:text-blue-300 transition-colors duration-300">Support</a>
      </div>
    </div>
  </div>
</footer>
