<div class="min-h-screen bg-black text-white flex flex-col items-center justify-center px-6 py-2 relative overflow-hidden">
  <!-- Animated Background Grid -->
  <div class="absolute inset-0 bg-grid opacity-10"></div>
  
  <!-- Moving Waves Background -->
  <div class="absolute inset-0">
    <svg class="absolute top-0 left-0 w-full h-auto opacity-20" id="waves"></svg>
  </div>

  <!-- Content -->
  <div class="relative z-10 max-w-4xl mx-auto text-center">
    <h1 class="text-5xl md:text-7xl font-extrabold neon-text cyberpunk-glitch mb-8">Support Center</h1>
    <p class="text-xl md:text-2xl text-blue-300 max-w-3xl mx-auto mb-12 cyberpunk-font">
      Need help? Our 24/7 support team is ready to assist you
    </p>

    <!-- Support Options Grid -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
      <!-- Live Chat -->
      <div class="bg-blue-500/5 p-6 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300">
        <h3 class="text-2xl font-bold text-blue-400 mb-3">Live Chat</h3>
        <p class="text-blue-300/80 mb-4">Get instant help from our support team</p>
        <button class="w-full px-6 py-3 bg-blue-600 text-white font-bold rounded-lg overflow-hidden transform hover:scale-105 transition-all duration-300 shadow-neon-button">
          Start Chat
        </button>
      </div>

      <!-- Email Support -->
      <div class="bg-blue-500/5 p-6 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300">
        <h3 class="text-2xl font-bold text-blue-400 mb-3">Email Support</h3>
        <p class="text-blue-300/80 mb-4">Send us a detailed message</p>
        <button class="w-full px-6 py-3 bg-blue-600 text-white font-bold rounded-lg overflow-hidden transform hover:scale-105 transition-all duration-300 shadow-neon-button">
          Contact Us
        </button>
      </div>
    </div>

    <!-- Quick Help Section -->
    <div class="bg-blue-500/5 p-8 rounded-lg border border-blue-500/20 mb-12">
      <h2 class="text-3xl font-bold text-blue-400 mb-6">Quick Help</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 text-left">
        <div class="p-4 hover:bg-blue-500/10 rounded-lg transition-all duration-300 cursor-pointer">
          <h4 class="text-lg font-bold text-blue-300">Getting Started</h4>
          <p class="text-blue-300/80">New to ZeroSum? Start here</p>
        </div>
        <div class="p-4 hover:bg-blue-500/10 rounded-lg transition-all duration-300 cursor-pointer">
          <h4 class="text-lg font-bold text-blue-300">Account Issues</h4>
          <p class="text-blue-300/80">Login and account management</p>
        </div>
        <div class="p-4 hover:bg-blue-500/10 rounded-lg transition-all duration-300 cursor-pointer">
          <h4 class="text-lg font-bold text-blue-300">Trading Help</h4>
          <p class="text-blue-300/80">Trading and analysis guides</p>
        </div>
        <div class="p-4 hover:bg-blue-500/10 rounded-lg transition-all duration-300 cursor-pointer">
          <h4 class="text-lg font-bold text-blue-300">API Documentation</h4>
          <p class="text-blue-300/80">Developer resources and guides</p>
        </div>
      </div>
    </div>

    <!-- Help Center Button -->
    <button class="px-8 py-4 border-2 border-blue-500 text-blue-400 font-bold text-lg rounded-lg shadow-md hover:bg-blue-500/10 transition-all duration-300 cyberpunk-font">
      Visit Help Center
    </button>
  </div>
</div>
  