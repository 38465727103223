<div *ngIf="auth.user$ | async as user" class="max-w-4xl mx-auto mt-10 px-6">
  <!-- Profile Card -->
  <div class="bg-white rounded-xl shadow-lg p-8">
    <!-- Profile Header -->
    <div class="flex flex-col md:flex-row items-center text-center md:text-left mb-8">
      <!-- Profile Image with hover effect -->
      <div class="mb-4 md:mb-0 md:mr-8">
        <div class="relative group">
          <img
            [src]="user.picture"
            alt="Profile Picture"
            class="w-32 h-32 rounded-full shadow-md border-4 border-white transition-transform group-hover:scale-105"
          />
          <div class="absolute inset-0 rounded-full bg-blue-500 opacity-0 group-hover:opacity-10 transition-opacity"></div>
        </div>
      </div>

      <!-- Profile Info with improved typography -->
      <div>
        <h2 class="text-3xl font-bold text-gray-800 mb-2">{{ user.name }}</h2>
        <div class="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <p class="text-lg text-gray-600">{{ user.email }}</p>
        </div>
      </div>
    </div>

    <!-- Profile Sections -->
    <div class="space-y-6">
      <div *ngFor="let section of profileSections" class="bg-gray-50 p-6 rounded-lg border border-gray-200">
        <h3 class="text-lg font-semibold text-gray-700 mb-4">{{ section.title }}</h3>
        
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div *ngFor="let item of section.items" 
               class="flex items-center p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
            <svg xmlns="http://www.w3.org/2000/svg" 
                 fill="none" 
                 viewBox="0 0 24 24" 
                 stroke-width="1.5" 
                 stroke="currentColor" 
                 class="w-5 h-5 text-blue-500 mr-3">
              <path stroke-linecap="round" stroke-linejoin="round" [attr.d]="item.icon"/>
            </svg>
            <div>
              <p class="text-sm font-medium text-gray-500">{{ item.label }}</p>
              <p class="text-base text-gray-900">
                <span *ngIf="isBoolean(item.value)" [class]="item.value ? 'text-green-600' : 'text-red-600'">
                  {{ item.value ? 'Yes' : 'No' }}
                </span>
                <span *ngIf="!isBoolean(item.value)">{{ item.value }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
