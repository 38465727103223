import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, distinctUntilChanged } from 'rxjs/operators';

export interface User {
  id?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  token?: string;
  name?: string;
  picture?: string;
}

export interface SignupRequest {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface JwtResponse {
  token: string;
  expiration: Date;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://api.zerosumanalytics.com/api/auth';
  private currentUserSubject: BehaviorSubject<User | null>;
  
  public currentUser: Observable<User | null>;
  public user$: Observable<User | null>;
  public isAuthenticated$: Observable<boolean>;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User | null>(
      this.getUserFromStorage()
    );
    
    this.currentUser = this.currentUserSubject.asObservable();
    this.user$ = this.currentUser.pipe(
      distinctUntilChanged(),
      shareReplay(1)
    );
    
    this.isAuthenticated$ = this.user$.pipe(
      map(user => !!user),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  private getUserFromStorage(): User | null {
    const storedUser = localStorage.getItem('currentUser');
    return storedUser ? JSON.parse(storedUser) : null;
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  signup(signupRequest: SignupRequest): Observable<string> {
    return this.http.post(`${this.apiUrl}/signup`, signupRequest, {
      responseType: 'text'  // Specify that we expect a text response
    });
  }

  login(email: string, password: string): Observable<User> {
    this.isLoadingSubject.next(true);
    const loginRequest: LoginRequest = { email, password };
    
    return this.http.post<JwtResponse>(`${this.apiUrl}/signin`, loginRequest).pipe(
      map(response => {
        const user: User = {
          email: email,
          token: response.token,
          name: email.split('@')[0], // Temporary name from email
          picture: 'https://api.dicebear.com/7.x/avatars/svg?seed=' + email // Default avatar
        };
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.isLoadingSubject.next(false);
        return user;
      })
    );
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  isAuthenticated(): boolean {
    return !!this.currentUserValue;
  }

  setLoading(loading: boolean) {
    this.isLoadingSubject.next(loading);
  }
}