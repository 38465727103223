<div class="min-h-screen bg-black text-white flex flex-col items-center justify-center px-6 relative overflow-hidden">
  <!-- Animated Background Grid -->
  <div class="absolute inset-0 bg-grid opacity-10"></div>
  
  <!-- Moving Waves Background -->
  <div class="absolute inset-0">
    <svg class="absolute top-0 left-0 w-full h-auto opacity-20" id="waves"></svg>
  </div>

  <!-- Content -->
  <div class="relative z-10 max-w-6xl mx-auto text-center">
    <h1 class="text-5xl md:text-7xl font-extrabold neon-text cyberpunk-glitch mb-8">Pricing Plans</h1>
    <p class="text-xl md:text-2xl text-blue-300 max-w-3xl mx-auto mb-12 cyberpunk-font">
      Choose the best plan for your trading needs
    </p>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <!-- Basic Plan -->
      <div class="pricing-card bg-blue-500/5 p-8 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300">
        <h2 class="text-3xl font-bold text-blue-400 mb-2">Basic</h2>
        <div class="text-4xl font-bold text-white mb-6">
          $0<span class="text-lg text-blue-300">/month</span>
        </div>
        <ul class="text-blue-300/80 space-y-3 mb-8">
          <li>Real-time Market Data</li>
          <li>Basic Trading Tools</li>
          <li>Community Access</li>
          <li>Email Support</li>
        </ul>
        <button class="w-full px-6 py-3 bg-blue-600 text-white font-bold rounded-lg overflow-hidden transform hover:scale-105 transition-all duration-300 shadow-neon-button">
          Get Started
        </button>
      </div>

      <!-- Pro Plan -->
      <div class="pricing-card bg-blue-500/5 p-8 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300 transform scale-105">
        <div class="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-400 to-blue-600"></div>
        <div class="text-blue-400 text-sm font-bold mb-4">MOST POPULAR</div>
        <h2 class="text-3xl font-bold text-blue-400 mb-2">Pro</h2>
        <div class="text-4xl font-bold text-white mb-6">
          $49<span class="text-lg text-blue-300">/month</span>
        </div>
        <ul class="text-blue-300/80 space-y-3 mb-8">
          <li>Advanced Analytics</li>
          <li>AI Trading Signals</li>
          <li>Priority Support</li>
          <li>Custom Alerts</li>
        </ul>
        <button class="w-full px-6 py-3 bg-blue-600 text-white font-bold rounded-lg overflow-hidden transform hover:scale-105 transition-all duration-300 shadow-neon-button">
          Subscribe Now
        </button>
      </div>

      <!-- Elite Plan -->
      <div class="pricing-card bg-blue-500/5 p-8 rounded-lg border border-blue-500/20 hover:border-blue-500/40 transition-all duration-300">
        <h2 class="text-3xl font-bold text-blue-400 mb-2">Elite</h2>
        <div class="text-4xl font-bold text-white mb-6">
          $99<span class="text-lg text-blue-300">/month</span>
        </div>
        <ul class="text-blue-300/80 space-y-3 mb-8">
          <li>Full API Access</li>
          <li>Custom Strategies</li>
          <li>24/7 Direct Support</li>
          <li>Advanced Portfolio Tools</li>
        </ul>
        <button class="w-full px-6 py-3 bg-blue-600 text-white font-bold rounded-lg overflow-hidden transform hover:scale-105 transition-all duration-300 shadow-neon-button">
          Upgrade to Elite
        </button>
      </div>
    </div>
  </div>
</div>
  